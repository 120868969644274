import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import moment from '../../../middleware/moment';

import { InCartProductDesc } from '../../common/products/productBlock';
import {
  shippingTxt,
  formatNumber,
} from '../../../middleware/common-functions';
import { useLazyQuery } from '@apollo/react-hooks';
import { GETORDER } from './queries';

const Order = ({ order_data, backUrl, type, sendToCart }) => {
  const [getOrderApi, { data: order_api_res }] = useLazyQuery(GETORDER);

  const [order_api, setOrder_api] = useState(null);

  useEffect(async () => {
    if (order_data.api_id) {
      getOrderApi({
        variables: {
          order_id: order_data.api_id,
        },
      });
    }
  }, []);
  useEffect(() => {
    if (order_api_res && order_api_res.xvoms.length) {
      const set_order = order_api_res.xvoms[0];
      setOrder_api(set_order);
    }
  }, [order_api_res]);

  return (
    <div className="order_data">
      <div className="preheader">
        <Link className="btn pl-0 text-capitalize " to={backUrl}>
          <i className="fas fa-chevron-left"></i> Atrás
        </Link>
      </div>
      <div className="header">
        <h1>
          {order_data.api_id ? `Orden #${order_data.api_id}` : order_data.name}
          <br />
          {type != 'quotes' && (
            <small>
              Estatus: {order_api ? order_api.statvt : 'Cargando...'}{' '}
            </small>
          )}
        </h1>
        <p>
          {moment(order_data.timestamp.toDate()).format('MMM DD YY HH:MM')}{' '}
          {order_data.ipgTransactionId && (
            <span>
              <br />
              ID Pago: #{order_data.ipgTransactionId}
            </span>
          )}
        </p>
      </div>
      <hr />
      <div className="row ">
        <div className="col-md-4 order-md-2">
          <div className="order_info border p-4">
            {order_data.delivery_type && (
              <div className="my-3">
                <h5>Forma de Entrega</h5>
                <p>{shippingTxt(order_data.delivery_type)}</p>
                <p>{renderHelpText(order_data)}</p>
              </div>
            )}
            {order_data.payment && (
              <div className="my-3">
                <h5>Forma de Pago</h5>
                <p>{renderPayment(order_data.payment)}</p>
              </div>
            )}
            <div className="my-3">
              <h5>Resumen del Pedido</h5>
              {type === 'orders' ? (
                <RenderTotals totals={order_data.totals} type={type} />
              ) : (
                <RenderTotals totals={order_data.total} type={type} />
              )}
            </div>
          </div>
          <OrderCtas type={type} sendToCart={sendToCart} />
        </div>
        <div className="col-md-8 product_cart order-md-1">
          <CartResume products={order_data.products} type={type} />
        </div>
      </div>
    </div>
  );
};

const RenderTotals = ({ totals, type }) => {
  if (type === 'quotes') {
    return (
      <div>
        <p>
          <span>Total</span> <strong>${totals.total}</strong>
        </p>
      </div>
    );
  }
  return (
    <div>
      <p>
        <span>Productos</span> <span>${formatNumber(totals.subtotal)}</span>
      </p>
      <p>
        <span>Envío</span> <span>${formatNumber(totals.shipping)}</span>
      </p>
      <p>
        <span>IVA</span> <span>${formatNumber(totals.tax)}</span>
      </p>
      <p>
        <span>Total</span> <strong>${formatNumber(totals.total)}</strong>
      </p>
    </div>
  );
};

const OrderCtas = ({ type, sendToCart }) => (
  <div className="order_ctas mt-2">
    {type === 'quotes' && (
      <button
        className="btn btn-warning btn-block text-left"
        onClick={sendToCart}
      >
        <i className="fas fa-cart-plus mr-2"></i>Enviar al Carrito
      </button>
    )}
  </div>
);

const CartResume = ({ products, type }) => (
  <div className="cart_resume">
    {products.map((product) => (
      <InCartProductDesc
        key={product.id}
        product={product}
        price
        review={type === 'orders'}
      />
    ))}
  </div>
);

function renderHelpText(data) {
  if (data.delivery_type === 'shipping') {
    return `${data.address.address} ${data.address.city} ${data.address.state} ${data.address.zip} `;
  } else {
    return 'Sucursal ';
  }
}

function renderPayment(payment) {
  if (payment === 'online') {
    return 'Pagado Online';
  } else if (payment === 'coppel') {
    return 'Pagado por Coppel Pay';
  } else {
    return 'Transferencia Electrónica';
  }
}

export default Order;
