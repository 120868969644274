import React, { useState, useEffect, useContext, useRef } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

import { Helmet } from 'react-helmet';

import {
  DeliveryTypes,
  DisabledBtnStates,
  MesesItem,
  PaymentTypes,
  Totals,
  SectionItem,
  UserSectionProps,
  DeliverySectionProps,
  BreadcrumbSectionProps,
  SectionTypes,
} from '../../../models/checkout_model';
import {
  UserDeliveryData,
  UserDeliveryProperty,
} from '../../../models/user_model';
import firebase, { db } from '../../../middleware/firebase';
import { getLogo, phoneFormat } from '../../../middleware/common-functions';
import axios from '../../../middleware/axios';
import { concatProductsTitle } from '../../../middleware/products';

import { AnalyticsContext } from '../../../context/analytics-context';
import { FullLoader, ModalDefault } from '../../common/modals/modals';
import Delivery from './delivery';
import CartResume from './cartResume';
import PaymentSection from './payment';

import OrderResume from './orderResume';
import NoDataInfo from './nodata';
import { getCart, updateCart } from '../../../middleware/cartFunctions';
import { setCart } from '../../../middleware/rappi';
import {
  CartItem,
  CartProductItem,
  PromotionItem,
  ShppingResponse,
  DistanceType,
} from '../../../models/cart_model';
import { UserType } from '../../../models/user_model';
import { OrderType } from '../../../models/order_model';
import { ZoneItem } from '../../../models/branch_model';
import {
  finalizePayWithCoppel,
  initiatePayWithCoppel,
} from '../../../middleware/coppel';
import { WhatsappIcon } from '../../common/buttons';
import { isMobile } from 'react-device-detect';

declare global {
  interface Window {
    Atrato_Pago_Events: any;
    vCustomer: any;
    dataLayer: any;
    RappiPagaCheckout: any;
  }
}

const user = JSON.parse(localStorage.getItem('user') || '{}');

const Checkout = () => {
  const logo = getLogo();
  let user_name_init = '';
  let user_lastname_init = '';
  let initial_section: SectionTypes = 'user';
  let initial_next = 0;
  const sectionsArr: SectionItem[] = [
    { name: 'Forma de Entrega', value: 'delivery' },
    { name: 'Forma de Pago', value: 'payment' },
    { name: 'Confirmación', value: 'resume' },
  ];
  if (!user.isAnonymous) {
    const name_init = user.displayName.split(' ');
    user_name_init = name_init[0];
    user_lastname_init = name_init[1];
    if (user.phone) {
      initial_section = 'delivery';
    }
    initial_next = 1;
  } else {
    sectionsArr.unshift({ name: 'Tu Información', value: 'user' });
  }
  const [products, setProducts] = useState<CartProductItem[]>([]);
  const [changePriceAlert, setChangePriceAlert] = useState(false);
  const [section, setSection] = useState<SectionTypes>(initial_section);
  const [nextSection, setNextSection] = useState(initial_next);
  const [disabled_btn, setDisabledBtn] = useState<DisabledBtnStates>(true);
  const [payloadSecure, setPayloadSecure] = useState<false | any>(false);
  const [totals, setTotals] = useState<Totals>({
    total: 0,
    subtotal: 0,
    shipping: 0,
    tax: 0,
    iva: 0.16,
  });
  const [delivery_type, setDelivery_type] = useState<DeliveryTypes>('pickup');
  const [deliveryData, setDeliveryData] = useState<UserDeliveryData>({
    name: user_name_init,
    lastname: user_lastname_init,
    phone_code: 'MX',
    phone: user.phone || '',
  });
  const [payment_type, setPayment_type] = useState<PaymentTypes | false>(false);
  const [placing_order_state, setPlacing_order_state] = useState('');
  const [active_city, setActive_city] = useState<string | false>(false);
  const [selectedBranch, setSelectedBranch] = useState<string | false>(false);
  const [cartData, setCartData] = useState<CartItem>({} as CartItem);
  const [branches, setBranches] = useState<ZoneItem[]>([]);
  const [addresses, setAddresses] = useState<UserDeliveryData[] | 'loaded'>([]);
  const [paymentAddress, setPaymentAddress] = useState<UserDeliveryData>(
    {} as UserDeliveryData
  );
  const [shippingcost, setShippingCost] = useState<ShppingResponse>(
    {} as ShppingResponse
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error_message, setError_message] = useState<null | string>(null);
  const [userData, setUserData] = useState<UserType>({} as UserType);
  const [promotion, setPromotion] = useState<PromotionItem | false>(false);

  const analyticsContext = useContext(AnalyticsContext);

  const [showCoppelPayModal, setShowCoppelPayModal] = useState(false);
  const [coppelPayPaymentRequest, setCoppelPayPaymentRequest] = useState('');
  const [coppelPayOrderId, setCoppelPayOrderId] = useState('');
  const [atratoOrderId, setAtrtoOrderId] = useState<string>();
  const pageTimeout = useRef<any>(null);
  const [isDelivery, setIsDelivery] = useState<boolean>(false);

  useEffect(() => {
    if (atratoOrderId) {
      // @ts-ignore next-line
      addbtnAtratoPago();
    }
  }, [atratoOrderId]);

  useEffect(() => {
    if (payloadSecure && payloadSecure.run) {
      placingOrder(true);
    }
  }, [payloadSecure]);

  useEffect(() => {
    getBranches();
    ///check if 3D secure
    getProducts();
    ///send vesta event
    if (typeof window.vCustomer != 'undefined') {
      window.vCustomer.startEvent(window.vCustomer.EVENT_TAGS.CHECKOUT);
    }
    if (user) {
      const set_user = { ...user };
      if (user.displayName) {
        set_user.name = user.displayName.split(' ')[0];
        set_user.lastname = user.displayName.split(' ')[1];
      }
      setUserData(set_user);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (totals.total > 0 && window.Atrato_Pago_Events) {
      initiliazeAtrato();
    }
    // eslint-disable-next-line
  }, [totals]);

  useEffect(() => {
    if (cartData.authenticationResponse && !cartData.payed && !cartData.error) {
      if (pageTimeout.current) {
        clearInterval(pageTimeout.current);
        pageTimeout.current = null;
      }
      const { params, type, transactionStatus } =
        cartData.authenticationResponse;
      if (transactionStatus === 'APPROVED') {
        placingOrder(false);
      } else {
        setPayloadSecure({ ...params, secure3dMethod: type, run: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartData]);

  useEffect(() => {
    if (error_message) {
      const element_error = document.getElementById('alert-shake');
      if (!element_error) {
        return;
      }
      if (element_error.classList.contains('alert-shake')) {
        element_error.classList.remove('alert-shake');
        setTimeout(() => {
          element_error.classList.add('alert-shake');
        }, 50);
      } else {
        element_error.classList.add('alert-shake');
      }
    }
  }, [error_message]);

  useEffect(() => {
    calculateTotals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, promotion, cartData]);

  useEffect(() => {
    if (section === 'user') {
      setDisabledBtn(false);
    } else if (section === 'delivery') {
      setDisabledBtn(false);
      if (userData && !deliveryData.street) {
        setDeliveryData({
          name: userData.name || '',
          lastname: userData.lastname || '',
          phone_code: 'MX',
          phone: userData.phone || '',
        });
      }
      loadAddreses();
      if (shippingcost.shippingCosts) {
        setShippingCost({} as ShppingResponse);
      }
    } else if (section === 'payment') {
      if (delivery_type === 'shipping') {
        getShippingDistance();
      } else {
        getBranchStock();
      }
    }
    const index = sectionsArr.findIndex((i) => i.value === section);
    setNextSection(index + 1);
  }, [section]);

  useEffect(() => {
    const shippingDistance = shippingcost?.distance?.distance?.value || 0;
    console.log('shippingDistance', shippingDistance);
    setIsDelivery(shippingDistance > 0);
  }, [shippingcost]);

  const getProducts = async () => {
    if (!user) {
      setLoading(false);
      return false;
    }

    const { cart_db, cart_products, promotion } = await getCart(true);
    setProducts(cart_products);
    setPromotion(promotion);
    if (cart_db) {
      setCartData(cart_db);
    }
    setLoading(false);
  };

  const initiliazeAtrato = () => {
    new window.Atrato_Pago_Events({
      onSuccess: (data: unknown) => {
        setPlacing_order_state('atrato');
        setTimeout(() => {
          placingOrder(false);
        }, 1500);
      },
      onPending: (data: unknown) => {},
      onError: (error: unknown) => {
        setPlacing_order_state('');
        console.log(`Result: ${JSON.stringify(error)}`);
        setError_message(
          `Lo sentimos tu solicitud con Atrato ha sido rechazada.`
        );
      },
      onRejected: (data: unknown) => {
        setPlacing_order_state('');
        console.log('index.tsx:194 | : unknown');
        console.log('On rejected');
        setError_message(`Lo sentimos `);
      },
    });
  };

  const calculateTotals = (back = false) => {
    if (!products || !totals) return false;
    const current_totals = { ...totals };
    let total = products
      .map((item) => Number(item.price) * +item.cant)
      .reduce((a, b) => a + b, 0);
    const subtotal = totals.iva ? total / (totals.iva + 1) : total / 1.16;
    const tax = total - subtotal;
    current_totals.subtotal = subtotal;
    current_totals.tax = tax;
    if (!back && current_totals.shipping) {
      total += current_totals.shipping;
    }
    if (promotion) {
      if (promotion.type === 'total') {
        total = total - promotion.value;
        current_totals.discount = promotion.value;
      } else if (promotion.type === 'percentage_all') {
        const discount_promo = total * promotion.value;
        total = total - discount_promo;
        current_totals.discount = discount_promo;
      } else if (
        promotion.type === 'percentage' ||
        promotion.type === 'amount'
      ) {
        current_totals.discount = cartData.discount;
      }
    }
    ///calculate pagos a meses
    if (current_totals.comission) {
      delete current_totals.comission;
    }
    current_totals.total = total;
    setTotals(current_totals);
  };
  const updateSection = (ev: React.MouseEvent<HTMLElement>) => {
    const section_next: SectionTypes = ev.currentTarget.dataset
      .next as SectionTypes;
    if (!section_next) {
      return;
    }
    switch (section_next) {
      case 'success':
        setDisabledBtn('hidden');
        break;
      case 'payment':
        calculateTotals();
        setDisabledBtn('hidden');
        break;
      default:
        setDisabledBtn(false);
        break;
    }

    ///validate data
    const valid_next = validateSection();
    if (valid_next) {
      window.scrollTo(0, 0);

      setSection(section_next);
    } else {
      setDisabledBtn(false);
    }
  };

  function removeUavaliavleProducts() {
    const no_deliver = shippingcost.shippingCosts.no_delivery.products_ids;
    const filtered_products = products.filter((product) => {
      const finded = no_deliver.find((id) => id === product.id);
      return finded ? false : true;
    });
    setProducts(filtered_products);
  }

  async function saveCartData(name: string, email: string) {
    await db.collection('cart').doc(user.id).set({
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      email_sent: false,
      has_email: true,
      user_email: email,
      user_display_name: name,
    });
    console.log('Data saved to cart colection');
  }

  function validateSection(): boolean {
    let valid = true;
    setError_message(null);
    if (section === 'user') {
      if (
        user.isAnonymous &&
        (!userData.email || !userData.name || !userData.lastname)
      ) {
        valid = false;
        setError_message('Favor proporcionar nombre y correo de contacto');
      } else {
        saveCartData(
          `${userData.name} ${userData.lastname}`,
          `${userData.email}`
        );
      }
    } else if (section === 'delivery' && delivery_type === 'shipping') {
      let missing_data: UserDeliveryProperty[] = [
        'name',
        'lastname',
        'phone',
        'street',
        'numext',
        'colonia',
        'city',
        'zip',
        'municipio',
        'state',
      ];
      const delivery_keys: UserDeliveryProperty[] = Object.keys(
        deliveryData
      ) as UserDeliveryProperty[];
      delivery_keys.forEach((key) => {
        const item: string = deliveryData[key] as string;
        if (item.length >= 1) {
          missing_data = missing_data.filter((i) => key != i);
          return true;
        }
        return false;
      });
      if (missing_data.length >= 1) {
        valid = false;
        addressError(missing_data[0]);
      }
    }
    return valid;
  }

  const addressError = (missing_data: UserDeliveryProperty) => {
    let message;
    switch (missing_data) {
      case 'name':
      case 'lastname':
        message = 'Favor de agregar el nombre y apellido de quien recibe';
        break;
      case 'phone':
        message = 'Favor de agregar número de teléfono';
        break;
      case 'street':
        message = 'Favor de agregar nombre de la calle';
        break;
      case 'numext':
        message = 'Favor de agregar número de la dirección';
        break;
      case 'colonia':
        message = 'Favor de agregar nombre de colonia';
        break;
      case 'city':
        message = 'Favor de agregar ciudad de envío';
        break;
      case 'zip':
        message = 'Favor de agregar número postal';
        break;
      case 'municipio':
        message = 'Favor de agregar el municipio de entrega';
        break;
      case 'state':
        message = 'Favor de seleccionar tu estado';
        break;
      default:
        message = 'Favor de llenar todos los campos necesarios';
        break;
    }
    setError_message(message);
  };
  const selectDeliveryType = (ev: { currentTarget: HTMLElement }) => {
    const type = ev.currentTarget.dataset.value as DeliveryTypes;
    if (type === delivery_type) return false;
    setDelivery_type(type);
  };
  const selectPayemntType = (ev: { currentTarget: HTMLElement }) => {
    const type = ev.currentTarget.dataset.value as PaymentTypes;
    if (type === payment_type) return false;
    setPayment_type(type);
  };
  const selectBranch = (ev: { currentTarget: HTMLElement }) => {
    const value = ev.currentTarget.dataset.value;
    if (!value || value === selectedBranch) return false;
    setDisabledBtn(false);
    setSelectedBranch(value);
  };

  const renderBackBtn = () => {
    if (!section || section === initial_section || section === 'success') {
      return null;
    }
    return (
      <button
        className="btn btn-sm text-capitalize"
        data-next={initial_section}
        onClick={updateSection}
      >
        <i className="fas fa-angle-left"></i> Atrás
      </button>
    );
  };

  const placeOrder = async () => {
    const order_obj = setOrderObject();
    ///check if there are products to deliver
    if (order_obj.products.length === 0) {
      setError_message('No hay productos en tu carrito');
      return;
    }
    if (payment_type === 'coppel') {
      const [res] = await initiatePayWithCoppel({
        setShowCoppelPayModal,
        userId: user.id,
        price: Math.ceil(totals.total),
        orderDescription: products.map((x) => x.title).join(', '),
        order_obj,
      });
      if (res) {
        setCoppelPayPaymentRequest(res.paymentRequest);
        setCoppelPayOrderId(res.orderId);
      }
    } else {
      placingOrder(false);
    }
  };

  const placingOrder = async (
    use_3d_secure = true,
    order_obj: OrderType | false = false,
    coppel: boolean = false
  ) => {
    console.log('index.tsx:507 | order_obj', order_obj);
    ///avoid duplicate orders
    if (placing_order_state === 'loading') return;
    if (!coppel) setPlacing_order_state(use_3d_secure ? 'paying' : 'loading');
    const new_totals = { ...totals };
    if (new_totals.comission) {
      new_totals.total += new_totals.comission;
    }
    if (!order_obj) {
      order_obj = setOrderObject();
    }
    if (use_3d_secure) {
      ///temporary store order in cart and localstorage
      localStorage.setItem('order', JSON.stringify(order_obj));
      localStorage.setItem('shippingcost', JSON.stringify(shippingcost));
      console.log('index.tsx:619 | payloadSecure', payloadSecure);
      return db
        .collection('cart')
        .doc(user.id)
        .collection('order')
        .add(order_obj)
        .then(() => {
          if (payloadSecure.cReq) {
            //@ts-ignore next line
            document.getElementById('payload_3d_secure').submit();
          } else if (payloadSecure.methodForm) {
            const form = document.getElementById(
              'tdsMmethodForm'
            ) as HTMLFormElement;
            if (!form) {
              setError_message(
                'Lo sentimos, ocurrió un error generando su solicitud de compra.'
              );
            }
          }
        });
    }

    if (coppel) {
      if (coppelPayPaymentRequest) {
        const [cpplPayData, error] = await finalizePayWithCoppel(
          coppelPayPaymentRequest
        );
        setShowCoppelPayModal(false);
        if (!cpplPayData) {
          alert(error);
          return;
        }
      } else {
        alert(
          'Lo sentimos, ocurrió un error generando su solicitud de compra.'
        );
        return;
      }
    }
    debugger;
    ///saving cart as order in DB
    const collection = db.collection('orders').doc(user.id);
    let order_id = '';
    if (coppelPayOrderId) {
      await collection
        .collection('orders')
        .doc(coppelPayOrderId)
        .set(order_obj);
      order_id = coppelPayOrderId;
    } else {
      const docRef = await collection.collection('orders').add(order_obj);
      order_id = docRef.id;
    }
    debugger;
    setPlacing_order_state('success');
    ///save Analytics event
    analyticsContext.event({
      action: 'purchase',
      value: order_obj.totals.total,
    });
    ///send vesta event
    if (typeof window.vCustomer != 'undefined') {
      window.vCustomer.finishEvent(window.vCustomer.EVENT_TAGS.CHECKOUT);
    }
    ///send soicos and gtag event
    if (typeof window.dataLayer != 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'thank_you_event',
        order_id: order_id,
        total: order_obj.totals.subtotal,
      });
    }
    updateCart({ show: true, authenticationResponse: null, error: null });
    setTimeout(() => {
      localStorage.removeItem('order');
      localStorage.removeItem('shippingcost');
      localStorage.removeItem('cart');
      window.location.href = '/gracias/' + order_id;
    }, 600);
    return true;
  };

  const setOrderObject = (): OrderType => {
    const location = JSON.parse(localStorage.getItem('location') || '{}');
    ///save resume info to use in other pages
    const products_data = {
      total: products.length,
      desc: concatProductsTitle(products),
    };
    const order_user_email = user.isAnonymous ? userData.email : user.email;
    const order_user_phone = userData.phone || user.phone || null;
    const new_totals = { ...totals };
    if (new_totals.comission) {
      new_totals.total += new_totals.comission;
    }

    const order_obj: OrderType = {
      status: 'placed',
      totals: new_totals,
      products_data,
      products,
      delivery_type,
      payment_type: payment_type || 'atrato',
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      zoneId: location.id,
      user_email: order_user_email,
      user_phone: order_user_phone,
      shipping: { ...shippingcost.shippingCosts },
      isMobile: isMobile,
      coupon: false,
    };

    if (atratoOrderId) {
      order_obj.local_order_id = atratoOrderId;
    }
    if (delivery_type === 'shipping') {
      order_obj.address = deliveryData;
      order_obj.address.phone = phoneFormat(
        deliveryData.phone,
        deliveryData.phone_code
      );
      order_obj.selectedBranch = {
        id: shippingcost.branch.id,
        idalma: shippingcost.branch.idalma,
      };
    } else if (active_city && branches) {
      let selected_branch = branches.find(
        (branch) => branch.id === active_city
      );
      ////make default branch
      if (!selected_branch) {
        selected_branch = branches[0];
      }
      const pickupBranch = selected_branch!.xiams?.find(
        (i) => i.id === selectedBranch
      );
      order_obj.selectedBranch = {
        id: pickupBranch!.id,
        idalma: pickupBranch!.idalma,
      };
    }
    if (promotion) {
      const { code, value } = promotion;
      order_obj.coupon = { code, value };
    }
    order_obj.timestamp = firebase.firestore.FieldValue.serverTimestamp();
    if (cartData.ipgTransactionId) {
      order_obj.ipgTransactionId = cartData.ipgTransactionId;
    }
    setTotals(order_obj.totals);
    setDelivery_type(order_obj.delivery_type);
    if (order_obj.selectedBranch && order_obj.selectedBranch.id) {
      setSelectedBranch(order_obj.selectedBranch.id);
    }
    if (order_obj.address) {
      setDeliveryData(order_obj.address);
    }
    if (user.isAnonymous && userData) {
      order_obj.user = { ...userData };
    } else {
      order_obj.user = {
        name: user.displayName,
        email: user.email,
      };
      if (user.phone || userData.phone) {
        order_obj.user.phone = user.phone || userData.phone;
      }
    }
    ///avoid sending in test page
    if (
      user.id === 'kpi5KCqXafa471LEUxL3AKnIi6A3' ||
      user.id === 'T7JU0PJOiXdzUOkCebkUUnB9lRp1' ||
      user.id === 'FoaUQNcF4SU3gtTxqwmg8qiyDv12'
    ) {
      order_obj.test_order = true;
    }

    return order_obj;
  };

  const renderIcon = () => {
    switch (placing_order_state) {
      case 'paying':
      case 'atrato':
        return 'far fa-credit-card pulse_anm';
      case 'paying_3d':
        return 'fas fa-fingerprint pulse_anm';
      case 'iframe':
        return 'fas fa-satellite-dish pulse_anm';
      case 'loading':
        return 'fas fa-circle-notch fa-spin';
      case 'success':
        return 'fas fa-check text-success big_entrance_anm';
      default:
        return null;
    }
  };
  const renderText = () => {
    switch (placing_order_state) {
      case 'paying':
        return 'Realizando Cobro';
      case 'atrato':
        return 'Conectando con Atrato';
      case 'iframe':
        return 'Conectando con tu Banco...';
      case 'paying_3d':
        return 'Redireccionando a pago seguro';
      case 'loading':
        return 'Estámos Enviando tu Órden';
      case 'success':
        return 'Órden Envíada!';
      default:
    }
  };
  const handleDelivery = (ev: { currentTarget: HTMLInputElement }) => {
    const type = ev.currentTarget.dataset.type;
    const value = ev.currentTarget.value;
    const currentDelivery = { ...deliveryData };
    //@ts-ignore next line
    currentDelivery[type] = value;
    if (currentDelivery.prev && type != 'message') {
      currentDelivery.prev = false;
    }
    setDeliveryData(currentDelivery);
  };

  const getBranches = () => {
    db.collection('warehouse')
      .get()
      .then((querySnapshot) => {
        const branches_obj: ZoneItem[] = [];
        querySnapshot.forEach((doc) => {
          branches_obj.push(doc.data() as ZoneItem);
        });
        setBranches(branches_obj);
      });
  };

  const handleCitySelect = (ev: { target: HTMLSelectElement }) => {
    const selected_city = ev.target.value;
    setActive_city(selected_city);
  };

  const loadAddreses = () => {
    if (addresses && addresses.length > 0) return false; /// avoid re runs
    db.collection('addresses')
      .doc(user.id)
      .collection('address')
      .get()
      .then(function (querySnapshot) {
        const loaded_addres: UserDeliveryData[] = [];
        let x = 1;
        querySnapshot.forEach(function (doc) {
          const db_address = doc.data() as UserDeliveryData;
          loaded_addres.push({ ...db_address, id: doc.id });
          x++;
        });
        if (x >= 1) {
          setAddresses(loaded_addres);
        } else {
          setAddresses('loaded');
        }
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        console.log('Error getting documents: ', error);
      });
  };

  const selectAddress = (ev: { currentTarget: HTMLElement }) => {
    const address_id = ev.currentTarget.dataset.attr;
    if (address_id === 'false') {
      setDeliveryData({
        name: user_name_init,
        lastname: user_lastname_init,
        phone_code: 'MX',
        phone: userData.phone || '',
        street: '',
        numext: '',
        colonia: '',
        numint: '',
        state: '',
        city: '',
        zip: '',
        municipio: '',
        countrycode: 'MX',
        message: '',
      });
      return;
    }
    if (addresses === 'loaded') {
      return;
    }
    const selected_address = addresses.find(
      (address) => address.id === address_id
    );
    if (selected_address) {
      selected_address.prev = address_id;
      setDeliveryData(selected_address);
      setDisabledBtn(false);
    }
  };

  const getShippingDistance = () => {
    if (shippingcost.shippingCosts) return false; ///avoid infinite loop
    const url = `scriptsMapGetDistance`;
    const current_totals = { ...totals };
    const location = JSON.parse(localStorage.getItem('location') || '{}');
    const currentDelivery = { ...deliveryData };
    const address = `${deliveryData.street} ${deliveryData.numext}, ${deliveryData.colonia},`;
    const city_data = `${deliveryData.city}, ${deliveryData.state}`;
    let distance: DistanceType[] | false = false;
    if (deliveryData.distance) {
      distance = deliveryData.distance;
    }
    const params = {
      from: { address, city_data, zip: deliveryData.zip },
      distance,
      user_id: user.id,
      user_type: user.isAnonymous ? 'anonymous' : 'user',
    };
    axios
      .post(url, { ...params })
      .then((response) => {
        if (response.data.error) {
          setSection('delivery');
          setError_message(
            'Parece que no podemos encontrar tu dirección, revisa que tu código postal sea correcto e intenta de nuevo'
          );
          return;
        }
        currentDelivery.address = address;
        setDeliveryData(currentDelivery);
        setShippingCost(response.data);
        current_totals.shipping = response.data.shippingCosts.shipping_cost
          ? parseFloat(response.data.shippingCosts.shipping_cost)
          : 0;
        ///calculate new taxes and totals
        current_totals.total += current_totals.shipping;
        setTotals(current_totals);

        ///store address distance
        if (response.data.distance) {
          const currentDelivery = { ...deliveryData };
          currentDelivery.distance = response.data.distance;
          setDeliveryData(currentDelivery);
        }

        ///validate if same branch and prices
        if (location.id !== response.data.branch.zone_id) {
          setChangePriceAlert(true);
          getProducts();
        }
      })
      .catch((err) => {
        setDisabledBtn(false);
        setSection('delivery');
        console.log('err', err);
      });
  };
  const getBranchStock = () => {
    if (shippingcost.shippingCosts) return false; ///avoid infinite loop
    const url = `queryBranchesGetStock`;
    const location = JSON.parse(localStorage.getItem('location') || '{}');
    const current_totals = { ...totals };
    const params = {
      branch_id: selectedBranch,
      xiaz_id: active_city,
      user_id: user.id,
      user_type: user.isAnonymous ? 'anonymous' : 'user',
    };
    axios
      .post(url, { ...params })
      .then((response) => {
        setShippingCost(response.data);
        current_totals.shipping = response.data.shippingCosts.shipping_cost
          ? parseFloat(response.data.shippingCosts.shipping_cost)
          : 0;
        ///calculate new taxes and totals
        current_totals.total += current_totals.shipping;
        setTotals(current_totals);
        ///validate if same branch and prices
        if (location.id !== active_city) {
          setChangePriceAlert(true);
          getProducts();
        }
      })
      .catch((err) => {
        setSection('delivery');
        setDisabledBtn(false);
        console.log('err', err);
      });
  };

  const handleUserData = (ev: { currentTarget: HTMLInputElement }) => {
    const current_user = { ...userData };
    const value = ev.currentTarget.value;
    const type = ev.currentTarget.id;
    //@ts-ignore next line
    current_user[type] = value;
    setUserData({ ...current_user });
  };
  const setOrderNumber = async () => {
    if (atratoOrderId) {
      return;
    }
    const doc = await db.collection('counters').doc('orders').get();
    const order_counter = doc.data();
    const counter = order_counter ? order_counter.count + 1 : 500;
    setAtrtoOrderId(counter.toLocaleString());
    //add counter for next order
    db.collection('counters').doc('orders').set({ count: counter });
  };

  const renderSection = () => {
    if (section === 'payment') {
      return (
        <PaymentSection
          total={totals.total}
          clickFun={updateSection}
          payment_type={payment_type}
          selectPayemntType={selectPayemntType}
          loading={loading}
          delivery_type={delivery_type}
          error_message={error_message}
          removeFun={removeUavaliavleProducts}
          products={products}
          changePriceAlert={changePriceAlert}
          shippingcost={shippingcost}
          isDelivery={isDelivery}
          zip={deliveryData.zip || ''}
        />
      );
    } else if (section === 'resume') {
      setOrderNumber();
      return (
        <OrderResume
          setError_message={setError_message}
          delivery_type={delivery_type}
          deliveryData={deliveryData}
          payment_type={payment_type}
          shippingcost={shippingcost}
          selectedBranch={selectedBranch}
          error_message={error_message}
          updateSection={updateSection}
          branch_data={
            selectedBranch && branches && active_city
              ? branches.find((branch) => branch.id === active_city)
              : null
          }
          placeOrder={placingOrder}
          setOrderObject={setOrderObject}
          totals={totals}
          user={user}
          payload={payloadSecure}
        />
      );
    } else if (section === 'user') {
      return (
        <UserSection
          clickFun={updateSection}
          handleUserData={handleUserData}
          user_data={userData}
          error_message={error_message}
        />
      );
    } else {
      return (
        <DeliverySection
          clickFun={updateSection}
          disabled_btn={disabled_btn ? true : false}
          delivery_type={delivery_type}
          setDelivery_type={selectDeliveryType}
          error_message={error_message}
          handleDelivery={handleDelivery}
          deliveryData={deliveryData}
          addresses={addresses != 'loaded' ? addresses : null}
          loading={loading}
          selectAddress={selectAddress}
          branches={branches}
          selectBranch={selectBranch}
          active_branch={selectedBranch}
          active_city={active_city}
          handleCitySelect={handleCitySelect}
        />
      );
    }
  };
  return (
    <div className="container checkout_page">
      {section === 'success' ? (
        <h1>
          <span>
            Pedido Realizado{' '}
            <i className="far fa-check-square text-success"></i>
          </span>{' '}
        </h1>
      ) : (
        <div className="checkout_header">
          <a href="/">
            <img src={logo} />
          </a>
          <h1>Realizar Pedido</h1>
        </div>
      )}
      {section !== 'success' && (
        <BreadcrumbSection
          clickFun={updateSection}
          active={section}
          items={sectionsArr}
        />
      )}
      {renderBackBtn()}
      <div className="row justify-content-between">
        {renderSection()}
        <div className="col-md-4 product_cart border-left ">
          {products && section !== 'success' ? (
            <CartResume
              cart_id={atratoOrderId}
              payment_type={payment_type}
              totals={totals}
              updateSection={updateSection}
              next={
                sectionsArr[nextSection]
                  ? sectionsArr[nextSection].value
                  : false
              }
              error_message={error_message}
              isDelivery={isDelivery}
              disabled_btn={disabled_btn}
              placeOrder={placeOrder}
            />
          ) : null}
        </div>
      </div>
      {placing_order_state && (
        <FullLoader icon={renderIcon()} text={renderText()} />
      )}
      <ModalDefault
        title="Pagar con Coppel Pay"
        action_txt="Finaliza tu compra"
        actionFun={() => placingOrder(false, false, true)}
        action_btn="primary d-none"
        hideFooter={false}
        toggle={() => setShowCoppelPayModal(!showCoppelPayModal)}
        show={showCoppelPayModal}
      >
        <div id="cpplPay"></div>
      </ModalDefault>
      <Helmet>
        <script src={process.env.REACT_APP_RAPPI_URL} crossOrigin="anonymous" />
      </Helmet>
      <WhatsappIcon />
      {totals.total > 0 && (
        <Helmet>
          <script
            type="text/javascript"
            src="https://atratopago.com/ecommerce_plugin/highslide-config.js"
          ></script>
        </Helmet>
      )}
    </div>
  );
};

const UserSection: React.FC<UserSectionProps> = ({
  clickFun,
  handleUserData,
  user_data,
  error_message,
}) => (
  <div className="col-md-7">
    <NoDataInfo handleUserData={handleUserData} user_data={user_data} />
    <div className="text-right my-5 d-md-none">
      {error_message && (
        <div className="alert alert-danger" id="alert-shake">
          <i className="fas fa-exclamation-circle mr-2"></i>
          {error_message}
        </div>
      )}
      <button
        className="btn btn-cart btn-block text-capitalize py-4 fixed"
        onClick={clickFun}
        data-next="payment"
      >
        Continuar
      </button>
    </div>
  </div>
);
const DeliverySection: React.FC<DeliverySectionProps> = ({
  clickFun,
  error_message,
  delivery_type,
  setDelivery_type,
  handleDelivery,
  deliveryData,
  addresses,
  loading,
  selectAddress,
  branches,
  selectBranch,
  active_branch,
  active_city,
  handleCitySelect,
  disabled_btn,
}) => (
  <div className="col-md-7">
    <Delivery
      delivery_type={delivery_type}
      error_message={error_message}
      setDelivery_type={setDelivery_type}
      clickFun={clickFun}
      handleDelivery={handleDelivery}
      deliveryData={deliveryData}
      addresses={addresses != 'loaded' ? addresses : null}
      loading={loading}
      selectAddress={selectAddress}
      branches={branches}
      selectBranch={selectBranch}
      active_branch={active_branch}
      active_city={active_city}
      handleCitySelect={handleCitySelect}
    />

    <div className="text-right my-5 ">
      {error_message && (
        <div className="alert alert-danger" id="alert-shake">
          <i className="fas fa-exclamation-circle mr-2"></i>
          {error_message}
        </div>
      )}
      <button
        className="btn text-capitalize py-3 px-5 btn-block btn-cart xs_hidden"
        onClick={clickFun}
        disabled={disabled_btn}
        data-next="payment"
      >
        Continuar
      </button>
    </div>
  </div>
);

const BreadcrumbSection: React.FC<BreadcrumbSectionProps> = ({
  clickFun,
  active,
  items,
}) => {
  const stepNum = items.findIndex((i) => i.value === active);

  return (
    <Breadcrumb>
      {items.map((item, key) => {
        return (
          <BreadcrumbItem active={active === item.value} key={`bread_${key}`}>
            <span
              className="hover"
              onClick={key < stepNum ? clickFun : undefined}
              data-next={item.value}
            >
              {item.name}
            </span>
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
};
export default Checkout;
