import React, { useState } from 'react';

import atrato_logo from '../../../assets/atrato.png';
import coppelpay_logo from '../../../assets/coppelpay_logo.png';
import { RichButton } from '../../common/buttons';
import Loader from '../../common/loader';
import ShippingCosts from './shippingCost';
import { DeliveryTypes, PaymentTypes } from '../../../models/checkout_model';
import { CartProductItem, ShppingResponse } from '../../../models/cart_model';

type PaymentSectionProps = {
  selectPayemntType: any;
  payment_type: PaymentTypes | false;
  clickFun: any;
  loading: boolean;
  delivery_type: DeliveryTypes;
  error_message: null | string;
  products: CartProductItem[];
  removeFun: any;
  shippingcost: ShppingResponse;
  isDelivery: boolean;
  changePriceAlert: any;
  zip: string;
  total: number;
};

const PaymentSection: React.FC<PaymentSectionProps> = ({
  selectPayemntType,
  payment_type,
  clickFun,
  loading,
  error_message,
  products,
  removeFun,
  shippingcost,
  isDelivery,
  delivery_type,
  changePriceAlert,
  zip,
  total,
}) => {
  const [disabled_btn, setDisabled_btn] = useState<boolean>(false);

  const disableButton = () => {
    setDisabled_btn(true);
  };

  const setRemoveFun = () => {
    removeFun();
    setDisabled_btn(false);
  };

  if (loading || !shippingcost.shippingCosts) {
    return (
      <div className="col-md-6">
        <Loader
          half
          txt={
            !shippingcost.shippingCosts
              ? delivery_type === 'shipping'
                ? 'Calculando costos de envío...'
                : 'Consultando inventario...'
              : 'Cargando'
          }
        />
      </div>
    );
  }
  return (
    <div className="payment_section col-md-8">
      <ShippingCosts
        removeFun={setRemoveFun}
        products={products}
        changePriceAlert={changePriceAlert}
        shippingcost={shippingcost}
        delivery_type={delivery_type}
        zip={zip}
        disableButton={disableButton}
        goToAddress={clickFun}
      />
      <h4>Selecciona tu método de pago:</h4>
      <div className="btn_rich_container mob_full">
        <RichButton
          title="Pago Seguro Online"
          classes={'online_btn'}
          small={true}
          icon="fa-fw far fa-credit-card"
          value="online"
          active={payment_type === 'online'}
          clickFun={selectPayemntType}
        />
        <RichButton
          title="Transferencia Bancaria"
          classes="transfer_btn"
          small={true}
          icon="fa-fw fas fa-university"
          value="transfer"
          active={payment_type === 'transfer'}
          clickFun={selectPayemntType}
        />
        <RichButton
          classes="paypal_btn"
          icon="fa-fw fab fa-cc-paypal "
          title="Paga con PayPal"
          value="paypal"
          active={payment_type === 'paypal'}
          clickFun={selectPayemntType}
        />
        <RichButton
          classes={' atrato_btn ' + (total < 1000 ? 'disabled' : '')}
          title="Paga a tu propio ritmo"
          small={true}
          value="atrato"
          active={payment_type === 'atrato'}
          clickFun={selectPayemntType}
          disabled={total < 1000}
        >
          <img src={atrato_logo} alt="Paga con Atrato" />
          {total < 1000 ? (
            <div className="small">Solo en compras mayores de $1,000 MXN</div>
          ) : null}
        </RichButton>
        <RichButton
          title="Usa tu crédito Coppel"
          classes={'coppel_btn' + (total > 15000 ? 'disabled' : '')}
          small={true}
          icon=""
          value="coppel"
          active={payment_type === 'coppel'}
          clickFun={selectPayemntType}
          disabled={total > 15000}
        >
          <img src={coppelpay_logo} alt="Paga con Rappi" />
          {total > 15000 ? (
            <div className="small">Solo compras de hasta $15,000 MXN</div>
          ) : null}
        </RichButton>
      </div>

      <p className="text-center mt-3">
        <small className="d-block text-muted mt-1">
          {' '}
          <i
            className="fa fa-check-circle text-success"
            aria-hidden="true"
          ></i>{' '}
          Tus pagos se realizan de forma segura con encriptación de 256 bits{' '}
        </small>
        {isDelivery && (
          <div>
            <small className="d-block text-muted mt-1">
              <i className="fas fa-info-circle" aria-hidden="true"></i> El costo
              del flete puede variar según factores como el volumen, peso o
              distancia del envío.
              <br />
              Te notificaremos de cualquier ajuste antes de procesar tu pedido.
            </small>
          </div>
        )}
      </p>
      {payment_type === 'transfer' && <TransferPayment />}

      {payment_type && (
        <React.Fragment>
          {error_message && (
            <div className="alert alert-danger" role="alert">
              <p>
                <i className="fas fa-exclamation-triangle"></i> {error_message}
              </p>
            </div>
          )}
          <button
            className="btn btn-dark text-capitalize py-4 py-md-3 btn-payment fixed"
            data-next="resume"
            disabled={disabled_btn ? true : false}
            onClick={clickFun}
          >
            Continuar
          </button>
          <p className="text-center">
            <small className="d-block text-muted mt-1">
              {' '}
              <i
                className="fa fa-check-circle text-success"
                aria-hidden="true"
              ></i>{' '}
              Tus pagos se realizan de forma segura con encriptación de 256 bits{' '}
            </small>
          </p>
          <small className="text-help mt-2 d-block text-muted">
            Necesitas ayuda con tu pedido o tienes dudas? <br />
            Estámos para atenderte en el correo <b>servicio@mathasa.com.mx</b> o
            al teléfono <b>614-442-9000</b>
          </small>
        </React.Fragment>
      )}
    </div>
  );
};

const TransferPayment = () => (
  <div className="transfer_data my-5">
    <p>Datos de Pago</p>
    <table className="table">
      <thead>
        <tr>
          <th>Banco</th>
          <th>CLABE</th>
          <th>Nombre</th>
          <th>Referencia</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Banregio</td>
          <td>058 150 6004 1980 0122</td>
          <td>Mathasa S.A. de C.V.</td>
          <td className="text-muted">Se te enviará por correo</td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default PaymentSection;
